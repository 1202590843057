import React from 'react'
import Img11 from '../../assets/ilustracao_tela11.png'
import Header from '../../components/Header'

import { Container, Content } from './styles'

const End: React.FC = () => {
  return (
    <>
      <Header />
      <Container>
        <Content>
          <strong>
            Pronto! Seu pedido de adesão ao Plano de Benefícios III foi salvo
            com sucesso!
          </strong>
          <img src={Img11} alt="img11" />

          <p>
            Agora nossos especialistas irão conferir todos os detalhes para a
            conclusão da sua adesão.
          </p>

          <div>
            <strong>ACESSE O NOSSO SITE:</strong>
            <a href="https://www.brfprevidencia.com.br/plano-iii/">
              www.brfprevidencia.com.br
            </a>
          </div>
        </Content>
      </Container>
    </>
  )
}

export default End
