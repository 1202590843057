import React, { useEffect } from 'react'
import { Switch } from 'react-router-dom'

import Route from './Route'

import BemVindo from '../pages/BemVindo'
import Register from '../pages/Register'
import ConfirmSMS from '../pages/ConfirmSMS'
import PersonalInfos from '../pages/PersonalInfos'
import NovoParticipante from '../pages/NovoParticipante'
import ParticipantsList from '../pages/ParticipantsList'
import FatcaInfo from '../pages/FatcaInfo'
import LGPD from '../pages/LGPD'
import Conclusion from '../pages/Conclusion'
import End from '../pages/End'
import Erro from '../pages/Erro'
import Contribution from '../pages/Contribution'
import NotFound from '../pages/NotFound'

import usePersistedState from '../hooks/usePersistedState'
import clientConfig from '../services/clientConfig'
import { ConfigData } from '../utils/interfaces'

// import Login from '../pages/Login'
// import GoalSelection from '../pages/GoalSelection'
// import Simulation from '../pages/Simulation'
// import ConfirmOwnership from '../pages/ConfirmOwnership'
// import Help from '../pages/Help'
// import Terms from '../pages/Terms'

const Routes: React.FC = () => {
  const { codCliente, tipo, plano } = clientConfig()

  const [configData, setConfigData] = usePersistedState<ConfigData>(
    'configData',
    {} as ConfigData,
  )
  useEffect(() => {
    setConfigData({
      ...configData,
      codCliente,
      tipo,
      plano,
    })
  }, [])

  return (
    <Switch>
      <Route path="/" exact component={BemVindo} />
      <Route path="/register" exact component={Register} />
      <Route path="/register/confirm-sms" component={ConfirmSMS} />
      <Route path="/personal-infos" component={PersonalInfos} />
      <Route path="/contribution" component={Contribution} />
      <Route path="/new-participant" component={NovoParticipante} />
      <Route path="/participants-list" component={ParticipantsList} />
      <Route path="/fatca" component={FatcaInfo} />
      <Route path="/lgpd" component={LGPD} />
      <Route path="/conclusion" component={Conclusion} />
      <Route path="/end" component={End} />
      <Route path="/erro" component={Erro} />

      <Route path="*" component={NotFound} />

      {/* <Route path="/goal-selection" component={GoalSelection} />
      <Route path="/simulation" component={Simulation} />
      <Route path="/help" component={Help} />
      <Route path="/confirm-ownership" component={ConfirmOwnership} />
      <Route path="/terms" component={Terms} />
      <Route path="/login" component={Login} /> */}
    </Switch>
  )
}

export default Routes
