import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { FiArrowRightCircle } from 'react-icons/fi'

import Button from '../../components/Button'
import Header from '../../components/Header'

import { Container, Content } from './styles'

const BemVindo: React.FC = () => {
  const history = useHistory()

  const handleStart = useCallback(async () => {
    history.push('/register')
  }, [history])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <strong>
            Olá, você é colaborador de uma das empresas do grupo BRF ?
          </strong>
          <span>
            Sim? Então este plano foi feito para você! Comece agora mesmo a
            planejar o seu futuro com a adesão do Plano de Benefícios III da BRF
            Previdência.
          </span>

          <Button color="orange" onClick={handleStart} width="large">
            Clique aqui e faça sua adesão!
            <FiArrowRightCircle size={45} />
          </Button>

          <small>
            O Plano III é um plano de previdência para os colaboradores das
            empresas que fazem parte do grupo BRF, onde o colaborador contribui
            com uma parte e a empresa com o mesmo valor*, garantindo maior
            segurança financeira para o seu futuro.
          </small>
          <small className="rules">*Limitado às regras regulamentares.</small>
        </Content>
      </Container>
    </>
  )
}

export default BemVindo
