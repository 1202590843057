import React from 'react'

import { Container } from './styles'

import logoImage from '../../assets/logo-brf.png'

const Logo: React.FC = () => {
  return <Container src={logoImage} alt="Logo" />
}

export default Logo
