import { transparentize } from 'polished';
import styled from 'styled-components';

interface BtnContatoProps {
  isActive: boolean
}

export const Container = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;

  margin: 0 auto;
  margin-bottom: 15px;
  max-width: 600px;
  padding: 0 5px;

  @media screen and (max-width: 766px) {
    max-width: 550px;
  }
`

export const Content = styled.div`
  background: #FFF;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 6px 12px rgba(61, 69, 67, 0.05);

  width: 100%;
  padding: 30px 25px;
  margin: 0 5px 10px;

  strong {
    text-align: center;
    font-size: 18px;
    color: #FF8636;

    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }

  > button {
    margin: 0 auto;
  }

  @media screen and (max-width: 766px) {
    padding: 20px 15px;

    > small {
      width: 90%;
    }
  }
`


export const RadioButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position:relative;

  width: 100%;
  padding: 16px 12px;
  margin-bottom: 12px;

  background: initial;
  border-radius: 4px;
  border: 1px solid ${transparentize(0.4, '#AEAEAE')};
  color: ${props => props.theme.colors.placeholder};
  transition: .2s;

  > label {
    transform: translateY(-12px);
    font-size: 12px;
    font-weight: bold;
  }

  > div {
    width: 100%;
    margin-top: 3px;
    margin-bottom: 3px;
    display: flex;
    flex-direction:row;
    margin: 0 auto;
    align-items: center;
    justify-content: space-evenly;

    &::placeholder {
      color: ${ props => props.theme.colors.placeholder};
    }

  }
`

export const BtnContato = styled.button<BtnContatoProps>`
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  padding: 3px;
  background: ${props => props.isActive ? 'linear-gradient(15deg, #F89A19, #FFD14D)' : 'transparent'} ;
  border: ${props => props.isActive ? 'none' : 'solid 1px #F89A19'};
  color: ${props => props.isActive? '#fff' : props.theme.colors.placeholder} ;
  border-radius: 4px;

  &:focus {
    text-decoration: underline;
  }
`

export const BtnVoltar = styled.button`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  border: none;
  background-color: unset;
  border-bottom: 1px solid transparent;
  color: ${({ theme }) => theme.colors.text};

  margin-bottom: 15px;

  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }

  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }
`

interface BtnModalProps {
  isActive: boolean;
}
export const BtnModal = styled.button<BtnModalProps>`
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  padding: 3px;
  background: ${props => props.isActive ? 'linear-gradient(15deg, #F89A19, #FFD14D)' : 'transparent'} ;
  border: ${props => props.isActive ? 'none' : 'solid 1px #F89A19'};
  color: ${props => props.isActive? '#fff' : props.theme.colors.placeholder} ;
  border-radius: 4px;
  margin: 20px 20px 0 0;
  &:focus {
    text-decoration: underline;
  }
`

export const BenefBox = styled.div`
  background: #FFF;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 6px 12px rgba(61, 69, 67, 0.05);
  position: relative;
  width: 100%;
  margin: 10px 0 10px;
  padding: 15px 25px 10px;

  > strong {
    font-size: 18px;
    font-weight: bold;
    color: #ff8636;
    margin-left: 15px;
    text-align: center;
  }
  p {
    text-align: center;
    font-size: 14px;

    > span {
     color: #ff8636;
     font-size: 14px;
     text-decoration: underline;
     cursor: pointer;
    }
  }

  div {
    position: relative;
    margin: 6px 0;
  }

  svg {
    position: absolute;
    width: 20px;
    height: 20px;

    top: -10px;
    right: 5px;
    cursor: pointer;
    color: ${props => props.theme.colors.error};
    z-index: 999;
  }

  h3 {
    color: ${({ theme }) => theme.colors.mainColor};
    text-align: center;
    font-size: 18px;
  }

  @media screen and (max-width: 766px) {
    padding: 20px 15px 10px;
  }
`

export const ContentBenef = styled.div`
  width: 100%;
  // border-bottom: 1px solid #d2d2d2;
  padding-top: 15px;
  > div {
    height: auto;
    padding-bottom: 10px;

    input {
      border: none;
      background: none;
    }
  }
`

export const Line = styled.main`
  width: 65%;
  height: 1px;
  margin: 25px auto 20px;
  background-color: ${props => props.theme.colors.backgroundTertiary};
`