/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useCallback, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { FormHandles, Scope } from '@unform/core'
import { Form } from '@unform/web'
import _cloneDeep from 'lodash/cloneDeep'

import { FiCheck, FiX } from 'react-icons/fi'

import usePersistedState from '../../hooks/usePersistedState'
import calculaIdade from '../../utils/calculaIdade'

import Header from '../../components/Header'
import Button from '../../components/Button'

import { Container, Content, ContentBenef, BenefBox, Line } from './styles'
import { Participant, UserData } from '../../utils/interfaces'
import apiRDStation from '../../services/apiRDStation'

interface CopiaDependent {
  data: {
    name: string
    cpf: string
    birthdate: string
  }
  details: {
    tipVinculo: string
    tipoBen: string
    grauParentesco: string
    dcrGrauParentesco: string
    proporcao: number
  }
}

const ParticipantsList: React.FC = () => {
  const [participants, setParticipants] = usePersistedState<Participant[]>(
    'participantsGroup',
    [],
  )
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)
  const dependentsListCopy: CopiaDependent[] = _cloneDeep(participants)
  const [partLength, setPartLength] = useState(participants.length)
  const arr = participants.map(participant => participant.details.proporcao)

  const formRef = useRef<FormHandles>(null)
  const history = useHistory()

  let soma = 0
  for (let i = 0; i < arr.length; i += 1) {
    soma += arr[i]
  }
  const [totalProporcao, setTotalProporcao] = useState(soma)

  useEffect(() => {
    setTotalProporcao(soma)
  }, [soma])

  const teste = useCallback(
    async arrAux => {
      await setParticipants([...arrAux])
      setTotalProporcao(100)
    },
    [setParticipants],
  )

  const handleProporcionalizar = useCallback(async () => {
    const value = (100 / partLength).toFixed(2)

    const contador = partLength

    for (let i = 0; i < contador; i += 1) {
      dependentsListCopy[i].details.proporcao = parseFloat(value)
    }
    teste(dependentsListCopy)
  }, [dependentsListCopy, partLength, teste])

  const handleRemove = useCallback(
    id => {
      setParticipants(() =>
        participants.filter((participant, idx) => idx !== id),
      )
      setPartLength(participants.length)
    },
    [participants, setParticipants],
  )

  useEffect(() => {
    setPartLength(participants.length)
  }, [setParticipants, participants, handleRemove])

  const handleClickSubmit = useCallback(() => formRef.current?.submitForm(), [])

  const handleSubmit = useCallback(async () => {
    apiRDStation(
      '/participants-list',
      userData.name,
      userData.email,
      userData.phone,
    )
    history.push('/fatca')
  }, [history, userData])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <BenefBox>
              {participants.length > 0 ? (
                <h3>Participantes</h3>
              ) : (
                <h3>Nenhum beneficiário informado.</h3>
              )}

              {dependentsListCopy.map((participant, idx) => (
                <ContentBenef key={Math.random()}>
                  <Scope path={`parts[${idx}]`}>
                    <FiX onClick={() => handleRemove(idx)} />
                    <div>
                      <strong>Nome: </strong>
                      <input value={participant.data.name} disabled />
                    </div>
                    <div>
                      <strong>Tipo de vínculo: </strong>
                      <div>
                        {participant.details.dcrGrauParentesco === undefined
                          ? ''
                          : participant.details.dcrGrauParentesco}
                      </div>
                    </div>
                    <div>
                      <strong>Idade: </strong>
                      <input
                        value={`${calculaIdade(
                          participant.data.birthdate,
                        )} anos`}
                        disabled
                      />
                    </div>
                    <div>
                      <strong>Proporção: </strong>
                      <label>{participant.details.proporcao}%</label>
                    </div>
                  </Scope>
                  <Line />
                </ContentBenef>
              ))}
            </BenefBox>
          </Form>
        </Content>

        {partLength === 0 ? (
          <Button
            type="button"
            fontSize="normal"
            color="white"
            width="large"
            onClick={() => history.push('/new-participant')}
          >
            Adicionar beneficiário
          </Button>
        ) : (
          <></>
        )}

        {totalProporcao !== 100 && partLength > 0 ? (
          <>
            <BenefBox>
              <p>
                Você ainda não distribuiu 100% da proporção entre seus
                beneficiários. Adicione mais beneficiários ou{' '}
                <span onClick={() => handleProporcionalizar()}>
                  clique aqui
                </span>{' '}
                para distribuir igualmente entre todos os informados.
              </p>
            </BenefBox>
            <Button
              type="button"
              fontSize="normal"
              color="white"
              width="large"
              onClick={() => history.push('/new-participant')}
            >
              Adicionar mais um beneficiário
            </Button>
          </>
        ) : (
          <>
            <Button
              type="button"
              fontSize="normal"
              color="orange"
              width="large"
              onClick={handleClickSubmit}
            >
              {partLength === 0 ? (
                <span>Não possuo beneficiários</span>
              ) : (
                <>
                  <FiCheck size={45} />
                  <span>Pronto! Avançar</span>
                </>
              )}
            </Button>
          </>
        )}

        {totalProporcao === 100 ? (
          <Button
            type="button"
            fontSize="small"
            color="white"
            width="small"
            onClick={() => history.push('/new-participant')}
          >
            Adicionar mais um beneficiário
          </Button>
        ) : (
          <></>
        )}
      </Container>
    </>
  )
}

export default ParticipantsList
