/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Container, Content, BtnVoltar } from './styles'
import Button from '../../components/Button'
import usePersistedState from '../../hooks/usePersistedState'
import Header from '../../components/Header'
import { UserData } from '../../utils/interfaces'
import apiRDStation from '../../services/apiRDStation'

interface ChecksLGPD {
  check1: boolean
  check2: boolean
}

const LGPD: React.FC = () => {
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)
  const [checksLGPD, setChecksLGPD] = usePersistedState<ChecksLGPD>(
    'checksLGPD',
    {} as ChecksLGPD,
  )

  const history = useHistory()

  const handleSubmit = useCallback(async () => {
    apiRDStation('/lgpd', userData.name, userData.email, userData.phone)
    history.push('/conclusion')
  }, [history, userData])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <strong>Lei Geral de Proteção de Dados</strong>
          <h4>Ao informar os dados eu:</h4>
          <div className="checkbox">
            <input
              type="checkbox"
              id="Lgpd1"
              checked={checksLGPD.check1}
              onChange={e => {
                setChecksLGPD({ ...checksLGPD, check1: e.target.checked })
              }}
            />
            <label htmlFor="Lgpd1">
              Autorizo o compartilhamento das informações fornecidas, nos termos
              da Lei nº 13.709/14, inclusive para receber informações sobre
              produtos, serviços e oportunidades financeiras por meio dos canais
              oficiais da BRF Previdência.
            </label>
          </div>
          <div className="checkbox">
            <input
              type="checkbox"
              id="Lgpd2"
              placeholder="Check2"
              checked={checksLGPD.check2}
              onChange={e => {
                setChecksLGPD({ ...checksLGPD, check2: e.target.checked })
              }}
            />
            <label htmlFor="Lgpd2">
              Autorizo o tratamento dos meus dados pessoais fornecidos nesse
              termo de adesão utilizando tais informações tão somente para os
              fins lícitos e previstos no Regulamento do PLANO aderido, bem como
              utilizá-las nas avaliações atuariais e financeiras.
            </label>
          </div>
        </Content>
        <Button
          type="button"
          fontSize="normal"
          color="orange"
          width="medium"
          onClick={handleSubmit}
          disabled={!checksLGPD.check1 || !checksLGPD.check2}
        >
          Continuar
        </Button>
        <BtnVoltar type="button" onClick={() => history.goBack()}>
          &lt; Anterior
        </BtnVoltar>
      </Container>
    </>
  )
}

export default LGPD
