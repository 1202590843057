import React, { useRef, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import moment from 'moment'
import * as Yup from 'yup'

import {
  FiMail,
  FiCalendar,
  FiSmartphone,
  FiPhone,
  FiUser,
} from 'react-icons/fi'
import { MdSecurity } from 'react-icons/md'

import { BiIdCard } from 'react-icons/bi'
import getValidationErrors from '../../utils/getValidationErrors'
import usePersistedState from '../../hooks/usePersistedState'
import validaCPF from '../../utils/validaCPF'

import InputSelect from '../../components/InputSelect'
import Button from '../../components/Button'
import Header from '../../components/Header'
import Input from '../../components/Input'

import { Container, Content } from './styles'
import { UserData } from '../../utils/interfaces'
import apiRDStation from '../../services/apiRDStation'

const PersonalInfos: React.FC = () => {
  const [userData, setUserData] = usePersistedState<UserData>(
    'userData',
    {} as UserData,
  )
  const [patrocinadora, setPatrocinadora] = useState({ label: '', value: '' })

  const history = useHistory()
  const formRef = useRef<FormHandles>(null)

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          name: Yup.string()
            .required('Seu nome é obrigatório.')
            .matches(/\s/g, 'Digite o nome completo')
            .min(5, 'Digite o nome completo'),
          email: Yup.string()
            .required('E-mail pessoal é obrigatório')
            .email('Digite um e-mail válido'),
          phone: Yup.string()
            .max(15, 'Telefone inválido')
            .required('Celular é obrigatório'),
          inscPlano: Yup.string().required('ID é obrigatório'),
          patrocinadora: Yup.string().required('Patrocinadora é obrigatória'),
        })

        await schema.validate(data, { abortEarly: false })

        await setUserData({
          ...userData,
          name: data.name,
          email: data.email,
          phone: data.phone,
          patrocinadora: patrocinadora.value,
          dcrPatrocinadora: patrocinadora.label,
          inscPlano: data.inscPlano,
        })
        apiRDStation('/personal-infos', data.name, data.email, data.phone)
        history.push('/contribution')
      } catch (err) {
        formRef.current?.setErrors(getValidationErrors(err))
      }
    },
    [setUserData, userData, patrocinadora, history],
  )

  const handleChangePatrocinadora = useCallback(e => {
    const t = e
    setPatrocinadora(t)
  }, [])

  const handleClick = useCallback(event => {
    event.preventDefault()
    formRef.current?.submitForm()
  }, [])

  return (
    <>
      <Header />
      <Container>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            name: userData.name,
            cpf: userData.cpf,
            admission:
              userData.admission === undefined
                ? ''
                : userData.admission.split('-').reverse().join('/'),
            email: userData.email,
            phone: userData.phone,
            patrocinadora: userData.patrocinadora,
            inscPlano: userData.inscPlano,
          }}
        >
          <Content>
            <strong>Informações Cadastrais</strong>
            <Input name="name" placeholder="*Nome Completo" icon={FiUser} />
            <Input
              placeholder="*CPF"
              name="cpf"
              icon={MdSecurity}
              type="tel"
              mask="cpf"
              disabled
            />
            <Input
              icon={FiCalendar}
              name="admission"
              placeholder="*Data de admissão"
              min="1930-01-01"
              disabled
            />
            <Input
              icon={FiMail}
              name="email"
              type="email"
              placeholder="*E-mail"
            />
            <Input
              name="phone"
              mask="phone"
              prefix="+55 | "
              placeholder="*Telefone com DDD"
              icon={FiSmartphone}
            />
            <InputSelect
              name="patrocinadora"
              value={patrocinadora}
              options={[
                { label: 'BRF S.A.', value: 6 },
                { label: 'Credi BRF', value: 7 },
                { label: 'BRF Previdência', value: 3 },
                { label: 'BRF Pet S/A', value: 2605 },
                { label: 'Mogiana Alimentos S/A', value: 2372 },
                { label: 'Hercosul Alimentos Ltda', value: 2345 },
                { label: 'Hercosul Distribuição Ltda', value: 2841 },
                { label: 'Hercosul Soluções em Transporte Ltda', value: 2408 },
              ]}
              onChange={e => handleChangePatrocinadora(e)}
              placeholder="*Empresa que atua"
            />
            <Input
              name="inscPlano"
              placeholder="*ID"
              icon={BiIdCard}
              type="text"
              maxLength={10}
            />
          </Content>
        </Form>

        <Button type="submit" color="orange" onClick={handleClick}>
          Avançar
        </Button>
      </Container>
    </>
  )
}

export default PersonalInfos
