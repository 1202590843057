function apiRDStation(
  indentifier: string,
  name: string,
  email: string,
  phone: string,
  ) {
  const keyRD = 'IJhJmOxHbgRDhCNfhkWsHxGeUYypRRaEpQOe'
  // const keyRD = ''
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer`,
    },
    body: JSON.stringify({
      event_type: 'CONVERSION',
      event_family: 'CDP',
      payload: {
        conversion_identifier: `ads-plano3${indentifier}`,
        name: name,
        email: email,
        job_title: '',
        state: '',
        city: '',
        country: '',
        personal_phone: phone,
        mobile_phone: '',
        twitter: '',
        facebook: '',
        linkedin: '',
        website: '',
        company_name: '',
        company_site: '',
        company_address: '',
        client_tracking_id: '',
        traffic_source: 'Google',
        traffic_medium: 'gpc',
        traffic_campaign: 'easter-50-off',
        traffic_value: 'easter eggs',
        available_for_mailing: true,
        cf_custom_field_name: 'Adesão BRF Plano III',
      },
    }),
  }
  fetch(
    `https://api.rd.services/platform/conversions?api_key=${keyRD}`,
    options,
  )
    .then(response => response.json())
    .then(response => console.log(response))
    .catch(err => console.error(err))
}

        // legal_bases: [
        //   {
        //     category: "communications",
        //     type: "consent",
        //     status: "granted"
        //   }
        // ],
export default apiRDStation
