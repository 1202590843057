/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { Base64 } from 'js-base64'
import Switch from 'react-switch'
import moment from 'moment'
import * as Yup from 'yup'
import { FiCalendar, FiSmartphone, FiMail, FiUser } from 'react-icons/fi'
import { MdSecurity } from 'react-icons/md'
import getValidationErrors from '../../utils/getValidationErrors'
import usePersistedState from '../../hooks/usePersistedState'
import validaCPF from '../../utils/validaCPF'
import api from '../../services/api'
import InputHidden from '../../components/InputHidden'
import Header from '../../components/Header'
import Button from '../../components/Button'
import Input from '../../components/Input'
import {
  Container,
  Content,
  RadioButton,
  BtnContato,
  Line,
  BtnModal,
} from './styles'
import { UserData, ConfigData, ErroProps } from '../../utils/interfaces'
import ModalBox from '../../components/Modal'
import apiRDStation from '../../services/apiRDStation'

const Register: React.FC = () => {
  const [userData, setUserData] = usePersistedState<UserData>(
    'userData',
    {} as UserData,
  )
  const [configData, setConfigData] = usePersistedState<ConfigData>(
    'configData',
    {} as ConfigData,
  )
  const [, setErroProps] = usePersistedState<ErroProps>(
    'erroProps',
    {} as ErroProps,
  )
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [tipoContato, setTipoContato] = useState('S')
  const [clicado, setClicado] = useState(false)
  const history = useHistory()
  const formRef = useRef<FormHandles>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [txtModal, setTxtModal] = useState('')

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          name: Yup.string()
            .required('Seu nome é obrigatório.')
            .matches(/\s/g, 'Digite o nome completo')
            .min(5, 'Digite o nome completo'),
          cpf: Yup.string()
            .required('CPF obrigatório')
            .test(
              '',
              'CPF inválido',
              () =>
                validaCPF(data.cpf.replaceAll('.', '').replace('-', '')) ||
                data.cpf === '',
            ),
          admission: Yup.string()
            .required('Data de admissão obrigatória')
            .test(
              '',
              'A data de admissão não pode ser maior que hoje.',
              () =>
                moment() >
                  moment(data.admission.split('/').reverse().join('-')) ||
                data.admission === '',
            )
            .test(
              '',
              'Data de admissão inválida',
              () =>
                moment(
                  data.admission.split('/').reverse().join('-'),
                ).isValid() || data.admission === '',
            ),
          tipContato: Yup.string().required(),
          email: Yup.string()
            .required('E-mail pessoal é obrigatório')
            .email('Digite um e-mail válido'),
          phone: Yup.string()
            .max(15, 'Telefone inválido')
            .required('Celular é obrigatório'),
        })

        await schema.validate(data, { abortEarly: false })

        setClicado(true)

        const dataAdmForm = data.admission.split('/').reverse().join('-')

        setUserData({
          ...userData,
          name: data.name,
          cpf: data.cpf,
          admission: dataAdmForm,
          email: data.email,
          phone: data.phone,
        })

        const parametros = Base64.encode(
          `{"versao":"${configData.tipo}",
            "plano": "${configData.plano}",
            "cliente":"${configData.codCliente}",
            "cpf":"${data.cpf.replaceAll('.', '').replace('-', '')}",
            "email":"${data.email === undefined ? '' : data.email}",
            "telefone":"${
              data.phone === undefined
                ? ''
                : `55${data.phone
                    .replace('(', '')
                    .replace(') ', '')
                    .replace('-', '')}`
            }",
            "admissao":"${dataAdmForm}",
            "envio":"${tipoContato}"}`,
        )

        const parametrosFinal = Base64.encode(parametros)
        // history.push('/register/confirm-sms')
        await api
          .get(`wsAutenticacao.rule?sys=ADZ&Entrada=${parametrosFinal}`)
          .then(res => {
            setConfigData({ ...configData, token: res.data.token, tipoContato })
            apiRDStation('/register', data.name, data.email, data.phone)
            history.push('/register/confirm-sms')
          })
          .catch(res => {
            if (res.message === 'Network Error') {
              setErroProps({
                title: res.message,
                description: 'Falha na conexão como servidor',
              })
              setClicado(false)
              history.push('/erro')
            } else if (res.message === 'Request failed with status code 500') {
              setErroProps({
                title: 'Erro interno no servidor',
                description: res.message,
              })
              setClicado(false)
              history.push('/erro')
            } else if (res.message === 'Request failed with status code 404') {
              setTxtModal(
                'Não localizamos os seus dados na base cadastral. Por favor, confira as informações digitadas ou entre em contato com a área de atendimento.',
              )
              setIsModalOpen(true)
              setClicado(false)
            } else if (res.message === 'Request failed with status code 401') {
              setTxtModal('Você já é participante.')
              setIsModalOpen(true)
              setClicado(false)
            } else {
              setTxtModal('Ops, algo deu errado. Tente novamente mais tarde.')
              setIsModalOpen(true)
              setClicado(false)
            }
          })
      } catch (err) {
        formRef.current?.setErrors(getValidationErrors(err))
      }
    },
    [
      configData,
      history,
      setConfigData,
      setErroProps,
      setUserData,
      tipoContato,
      userData,
    ],
  )

  const toggleAcceptTerms = useCallback(() => {
    setAcceptTerms(!acceptTerms)
  }, [acceptTerms, setAcceptTerms])

  const handleConfirmar = useCallback(event => {
    event.preventDefault()
    formRef.current?.submitForm()
  }, [])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <strong>Informe seus dados para prosseguir:</strong>

          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{
              name: userData.name,
              cpf: userData.cpf,
              admission:
                userData.admission === '' || userData.admission === undefined
                  ? ''
                  : userData.admission.split('-').reverse().join('/'),
              phone: userData.phone,
              email: userData.email,
            }}
          >
            <Input placeholder="Nome completo" name="name" icon={MdSecurity} />
            <Input
              placeholder="CPF"
              name="cpf"
              icon={FiUser}
              type="tel"
              mask="cpf"
            />
            <Input
              icon={FiCalendar}
              name="admission"
              mask="date"
              placeholder="Data de admissão"
            />
            <Input
              name="phone"
              mask="phone"
              prefix="+55 | "
              placeholder="Celular com DDD"
              icon={FiSmartphone}
            />
            <Input
              icon={FiMail}
              name="email"
              id="email"
              type="email"
              placeholder="E-mail"
              maxLength={150}
            />

            <RadioButton>
              <label>Enviar código de confirmação por: </label>
              <div>
                <BtnContato
                  type="button"
                  isActive={tipoContato === 'S'}
                  onClick={() => setTipoContato('S')}
                >
                  SMS
                </BtnContato>
                <BtnContato
                  type="button"
                  isActive={tipoContato === 'E'}
                  onClick={() => setTipoContato('E')}
                >
                  E-mail
                </BtnContato>
              </div>
            </RadioButton>
            <InputHidden name="tipContato" value={tipoContato} type="hidden" />
          </Form>

          <Line />

          <div>
            <small>
              Ao continuar navegando no site, você concorda com as condições de{' '}
              <a
                href="https://www.brfprevidencia.com.br/politica-de-privacidade/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacidade
              </a>{' '}
              e{' '}
              <a
                href="https://www.brfprevidencia.com.br/politica-de-privacidade/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Termos de uso
              </a>{' '}
              do site BRF Previdência.
            </small>
            <Switch
              onChange={toggleAcceptTerms}
              checked={acceptTerms}
              checkedIcon={false}
              uncheckedIcon={false}
              height={20}
              width={40}
              handleDiameter={16}
              offColor="#FBADB4"
              offHandleColor="#fff"
              onHandleColor="#fff"
              onColor="#31D19E"
            />
          </div>
        </Content>

        <Button
          type="submit"
          color="orange"
          onClick={handleConfirmar}
          disabled={!acceptTerms || clicado}
        >
          Confirmar
        </Button>
        <ModalBox isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          {txtModal}
          <BtnModal isActive onClick={() => handleCloseModal()}>
            Ok
          </BtnModal>
        </ModalBox>
      </Container>
    </>
  )
}

export default Register
