/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
import React, { useRef, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import { FiDollarSign, FiPercent } from 'react-icons/fi'
import Collapse from '@material-ui/core/Collapse'
import getValidationErrors from '../../utils/getValidationErrors'
import usePersistedState from '../../hooks/usePersistedState'
import { formatValue } from '../../utils/formatValues'
import InputSelect from '../../components/InputSelect'
import Button from '../../components/Button'
import Header from '../../components/Header'
import Input from '../../components/Input'
import {
  Container,
  Content,
  PercentualBox,
  ButtonSimulationCalc,
  ValueSimulation,
  InfoContent,
  RadioButton,
  NoContribCheck,
  BtnContato,
  BtnVoltar,
  Line,
  BtnModal,
} from './styles'
import { UserDetails, UserData } from '../../utils/interfaces'
import InputHidden from '../../components/InputHidden'
import ModalBox from '../../components/Modal'
import { ValorMascarar } from '../../utils/masks'
import apiRDStation from '../../services/apiRDStation'

const PersonalInfos: React.FC = () => {
  const [userDetails, setUserDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)
  const [URPIII] = useState(5976.52) // useState(5712.54) // useState(5400.15) // useState(4906.5)
  const [tipo, setTipo] = usePersistedState('tipoContrib', 'P')
  const [salarioValue, setSalarioValue] = useState(userDetails.salario)
  const [vlrCtbBasica, setVlrCtbBasica] = useState(
    userDetails.contribuicaoBasica > 0 ? userDetails.contribuicaoBasica : 0,
  )
  const [vlrCtbTotal, setVlrCtbTotal] = useState(
    userDetails.contribuicaoBasica * 2,
  )
  const [txtPct, setTxtPct] = useState(
    userDetails.pctContribuicaoBasica > 0
      ? userDetails.pctContribuicaoBasica
      : 7,
  )
  const [vis1, setVis1] = useState(false)
  const [vis2, setVis2] = useState(false)
  const [pctCtbSup, setPctCtbSup] = useState(
    userDetails.pctContribuicaoSuplementar,
  )
  const [vlrCtbSup, setVlrCtbSup] = useState(
    userDetails.contribuicaoSuplementar > 0
      ? userDetails.contribuicaoSuplementar
      : 0,
  )
  const [periodicidade, setPeriodicidade] = useState({
    label:
      userDetails.periodicidade === 'S'
        ? 'Semestral'
        : userDetails.periodicidade === 'T'
          ? 'Trimestral'
          : 'Mensal',
    value:
      userDetails.periodicidade === undefined ||
        userDetails.periodicidade === ''
        ? 'M'
        : userDetails.periodicidade,
  })

  const history = useHistory()
  const formRef = useRef<FormHandles>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [txtModal, setTxtModal] = useState('')

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const handleMudaContrib = useCallback(
    (pct, sal) => {
      const faixa1 = URPIII * 0.015
      const residual1 = URPIII * 1.65 - URPIII
      const faixa2 = residual1 * 0.04
      const residual2 = sal - URPIII * 1.65
      const faixa3 = residual2 * (pct / 100)
      const valueC = faixa1 + faixa2 + faixa3
      setVlrCtbBasica(valueC)
      setVlrCtbTotal(2 * valueC)
      setTxtPct(pct)
    },
    [URPIII],
  )

  const minusContrib = useCallback(() => {
    handleMudaContrib(txtPct - 1, salarioValue)
  }, [handleMudaContrib, salarioValue, txtPct])

  const plusContrib = useCallback(() => {
    handleMudaContrib(txtPct + 1, salarioValue)
  }, [handleMudaContrib, salarioValue, txtPct])

  const handleCalculaContribuicao = useCallback(
    sal => {
      setVlrCtbBasica(0)
      setSalarioValue(sal)
      if (sal !== null && sal > 0) {
        const pesoUrp = sal / URPIII
        if (pesoUrp > 0 && pesoUrp <= 1.0) {
          const valueC = sal * 0.015
          setVlrCtbBasica(valueC)
          setVlrCtbTotal(2.0 * valueC)
          setTxtPct(1.5)
          setVis1(false)
        } else if (pesoUrp > 1.0 && pesoUrp <= 1.65) {
          const faixa1 = URPIII * 0.015
          const residual = sal - URPIII
          const faixa2 = residual * 0.04
          const valueC = faixa1 + faixa2
          setVlrCtbBasica(valueC)
          setVlrCtbTotal(2.0 * valueC)
          setTxtPct(4.0)
          setVis1(false)
        } else {
          setVis1(true)
          handleMudaContrib(7, sal)
        }
      }
    },
    [URPIII, handleMudaContrib],
  )

  const handleMudaContribSup = useCallback(
    valor => {
      // const v = parseFloat(valor.replace('.', '').replaceAll(',', '.'))
      const v = parseFloat(valor)
      const valueCSup = (salarioValue * (isNaN(v) ? 0 : v)) / 100
      setPctCtbSup(v)
      setVlrCtbSup(valueCSup)
    },
    [salarioValue],
  )
  const handleMudaVlrSup = useCallback(valor => {
    const valueCSup = parseFloat(valor.replace('.', '').replaceAll(',', '.'))
    setVlrCtbSup(isNaN(valueCSup) ? 0 : valueCSup)
  }, [])

  const mudarSalario = useCallback(
    valor => {
      const v = valor.replace(',', '').replaceAll('.', '')
      if (isNaN(v)) {
        setSalarioValue(salarioValue)
      } else {
        const m = Math.floor(v.length - 2)
        const a = `${v.substr(0, m)}.${v.substr(m)}`
        const f = parseFloat(a)
        setSalarioValue(f)
        handleCalculaContribuicao(f)
      }
    },
    [salarioValue, handleCalculaContribuicao],
  )

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({})

        let schema = null
        if (!vis2) {
          schema = Yup.object().shape({
            salario: Yup.string().required('Campo obrigatório'),
            PercContribSup: Yup.string().when('tipoCS', {
              is: 'P',
              then: Yup.string()
                .required('Percentual obrigatório')
                .test(
                  '',
                  'Escolha um valor entre 1 e 50',
                  () =>
                    parseFloat(data.PercContribSup) > 0 &&
                    parseFloat(data.PercContribSup) <= 50,
                ),
            }),
            ValorContribSup: Yup.string().when('tipoCS', {
              is: 'F',
              then: Yup.string()
                .required('Valor obrigatório')
                .test(
                  '',
                  `Escolha um valor entre 0 e ${ValorMascarar(
                    (salarioValue - vlrCtbBasica).toFixed(2).toString(),
                  )}`,
                  () =>
                    parseFloat(
                      data.ValorContribSup.replace('.', '').replaceAll(
                        ',',
                        '.',
                      ),
                    ) > 0 &&
                    parseFloat(
                      data.ValorContribSup.replace('.', '').replaceAll(
                        ',',
                        '.',
                      ),
                    ) <=
                    salarioValue - vlrCtbBasica,
                ),
            }),
          })
        } else {
          setPctCtbSup(0)
          setVlrCtbSup(0)

          schema = Yup.object().shape({
            salario: Yup.string().required('Campo obrigatório'),
          })
        }

        await schema.validate(data, { abortEarly: false })

        setUserDetails({
          ...userDetails,
          salario: salarioValue * 1,
          contribuicaoBasica: vlrCtbBasica,
          pctContribuicaoBasica: txtPct,
          contribuicaoSuplementar: !vis2 ? vlrCtbSup * 1 : 0,
          tipoContribuicaosuplementar: !vis2 ? tipo : 'N',
          pctContribuicaoSuplementar: tipo === 'P' && !vis2 ? pctCtbSup * 1 : 0,
          periodicidade: periodicidade.value,
        })

        apiRDStation(
          '/contribution',
          userData.name,
          userData.email,
          userData.phone,
        )
        history.push('/new-participant')
      } catch (err) {
        formRef.current?.setErrors(getValidationErrors(err))
      }
    },
    [
      vis2,
      setUserDetails,
      userDetails,
      userData,
      salarioValue,
      vlrCtbBasica,
      txtPct,
      tipo,
      vlrCtbSup,
      pctCtbSup,
      periodicidade.value,
      history,
    ],
  )

  const handleClick = useCallback(() => {
    formRef.current?.submitForm()
  }, [])

  const handleChangePeriodicidade = useCallback(e => {
    const t = e
    setPeriodicidade(t)
  }, [])

  const handleVisibility2 = useCallback(() => {
    setVis2(!vis2)
  }, [vis2])

  return (
    <>
      <Header />
      <Container>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            salario:
              userDetails.salario === undefined
                ? ''
                : ValorMascarar(userDetails.salario.toFixed(2).toString()),
            PercContribSup: userDetails.pctContribuicaoSuplementar,
            ValorContribSup:
              userDetails.contribuicaoSuplementar === undefined
                ? ''
                : ValorMascarar(
                  userDetails.contribuicaoSuplementar.toFixed(2).toString(),
                ),
          }}
        >
          <Content>
            <strong>Contribuição básica</strong>
            <p>
              A contribuição básica é o valor a ser descontado mensalmente de
              sua folha de pagamento e a empresa fará a contribuição no mesmo
              valor.
            </p>
            <p>Insira suas informações e veja como ficará sua contribuição.</p>
            <Input
              icon={FiDollarSign}
              name="salario"
              mask="currency"
              type="text"
              placeholder="Qual o seu salário R$?"
              onChange={e => mudarSalario(e.target.value)}
            />

            <Collapse in={vis1} className="collapse">
              <PercentualBox>
                <p>Com quantos % você quer contribuir?</p>
                <div>
                  <ButtonSimulationCalc
                    type="button"
                    disabled={txtPct <= 5}
                    isSelected={txtPct > 5}
                    onClick={minusContrib}
                  >
                    -
                  </ButtonSimulationCalc>
                  <ValueSimulation>{txtPct}</ValueSimulation>
                  <ButtonSimulationCalc
                    type="button"
                    disabled={txtPct >= 7}
                    isSelected={txtPct < 7}
                    onClick={plusContrib}
                  >
                    +
                  </ButtonSimulationCalc>
                </div>
              </PercentualBox>
            </Collapse>

            <InfoContent>
              <div>
                <p>Valor da sua contribuição básica: </p>
                <strong>{formatValue(vlrCtbBasica)}</strong>
              </div>
              <div>
                <p>Valor da contribuição básica da sua empresa: </p>
                <strong>{formatValue(vlrCtbBasica)}</strong>
              </div>
              <div>
                <div>
                  <p>Valor total de contribuição básica ao Plano: </p>
                  <small>(participante + empresa)</small>
                </div>
                <strong>{formatValue(vlrCtbBasica * 2)}</strong>
              </div>
            </InfoContent>
          </Content>

          <Collapse in={salarioValue >= 0} className="collapse">
            <Content>
              <strong>Contribuição adicional (opcional)</strong>
              <p>
                Você pode contribuir com um valor adicional (além da
                contribuição básica), que te ajudará ainda mais na formação de
                sua reserva financeira. Basta se cadastrar e escolher a melhor
                forma.
              </p>
              <p>
                Essa contribuição também será descontada da sua folha de
                pagamento mensalmente, porém deste valor não há contrapartida da
                empresa.
              </p>

              <InputHidden name="tipoCS" type="hidden" value={tipo} />
              <Collapse in={!vis2} className="collapse">
                <RadioButton>
                  <div>
                    <BtnContato
                      type="button"
                      isActive={tipo === 'P'}
                      onClick={() => setTipo('P')}
                    >
                      Percentual
                    </BtnContato>
                    <BtnContato
                      type="button"
                      isActive={tipo === 'F'}
                      onClick={() => setTipo('F')}
                    >
                      Valor fixo
                    </BtnContato>
                  </div>
                </RadioButton>
                <Collapse in={tipo === 'P'}>
                  <Input
                    icon={FiPercent}
                    name="PercContribSup"
                    type="number"
                    min="1"
                    max="50"
                    placeholder="Qual % você quer contribuir? (Máximo 50%)"
                    onBlur={e => handleMudaContribSup(e.target.value)}
                    onChange={e => handleMudaContribSup(e.target.value)}
                  />
                </Collapse>
                <Collapse in={tipo === 'F'}>
                  <Input
                    icon={FiDollarSign}
                    name="ValorContribSup"
                    mask="currency"
                    max={salarioValue - vlrCtbBasica}
                    placeholder={`Quanto R$ você quer contribuir? (Máximo: R$${ValorMascarar(
                      (salarioValue - vlrCtbBasica).toFixed(2).toString(),
                    )})`}
                    onBlur={e => handleMudaVlrSup(e.target.value)}
                    onChange={e => handleMudaVlrSup(e.target.value)}
                  />
                </Collapse>
                {tipo === 'P' || tipo === 'F' ? (
                  <InputSelect
                    defaultValue={periodicidade}
                    name="periodicidade"
                    options={[
                      { label: 'Mensal', value: 'M' },
                      { label: 'Trimestral', value: 'T' },
                      { label: 'Semestral', value: 'S' },
                    ]}
                    onChange={e => handleChangePeriodicidade(e)}
                    placeholder="Periodicidade da sua contribuição adicional"
                  />
                ) : (
                  <></>
                )}

                <InfoContent>
                  <div>
                    <p>Valor da contribuição suplementar:</p>
                    <strong>{formatValue(vlrCtbSup)}</strong>
                  </div>
                </InfoContent>

                <Line />
              </Collapse>

              <NoContribCheck>
                <div>
                  <input
                    name="checkbox"
                    type="checkbox"
                    checked={vis2}
                    onChange={() => handleVisibility2()}
                  />
                  <small>Não quero cadastrar uma contribuição adicional.</small>
                </div>
              </NoContribCheck>

              <div className="rules">
                <small>
                  Está em dúvida? Não se preocupe, depois você poderá alterar
                  este valor sempre que desejar.
                </small>
              </div>
            </Content>
          </Collapse>
        </Form>

        <Button
          type="submit"
          color="orange"
          onClick={handleClick}
          disabled={vlrCtbBasica === 0 || tipo === ''}
        >
          Continuar
        </Button>
        <BtnVoltar type="button" onClick={() => history.goBack()}>
          &lt; Anterior
        </BtnVoltar>
        <ModalBox isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          {txtModal}
          <BtnModal isActive onClick={() => handleCloseModal()}>
            Ok
          </BtnModal>
        </ModalBox>
      </Container>
    </>
  )
}

export default PersonalInfos
