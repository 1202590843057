import styled from 'styled-components'

export const Container = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;

  margin: 0 auto;
  margin-bottom: 15px;
  max-width: 600px;
  padding: 0 5px;

  @media screen and (max-width: 766px) {
    max-width: 550px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  background: #FFF;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 6px 12px rgba(61, 69, 67, 0.05);

  width: 100%;
  min-height: 300px;
  padding: 30px 25px 40px;
  margin-bottom: 10px;
  text-align: center;

  > strong {
    text-align: center;
    font-size: 18px;
    color: #FF8636;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
  }

  span {
    margin-bottom: 15px;
  }


  small {
    margin-top: 12px;
    width:  85%;
  }

  small.rules {
    font-style: italic;
  }

  @media screen and (max-width: 766px) {
    padding: 30px 15px;
  }
`
