/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Switch from 'react-switch'
import { Base64 } from 'js-base64'
import { FiCheck, FiClock } from 'react-icons/fi'
import { CircularProgress } from '@material-ui/core'
import {
  Container,
  Content,
  Confirmation,
  InfoContent,
  Line,
  Participants,
  BtnVoltar,
  BtnModal,
} from './styles'
import api from '../../services/api'
import Button from '../../components/Button'
import usePersistedState from '../../hooks/usePersistedState'
import ModalBox from '../../components/Modal'
import {
  UserData,
  UserDetails,
  ConfigData,
  Participant,
  ErroProps,
} from '../../utils/interfaces'
import Header from '../../components/Header'
import { formatValue } from '../../utils/formatValues'
import apiRDStation from '../../services/apiRDStation'

const Conclusion: React.FC = () => {
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)
  const [configData] = usePersistedState<ConfigData>(
    'configData',
    {} as ConfigData,
  )
  const [userDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const [participants] = usePersistedState<Participant[]>(
    'participantsGroup',
    [],
  )
  const [, setErroProps] = usePersistedState<ErroProps>(
    'erroProps',
    {} as ErroProps,
  )

  const [acceptTerms, setAcceptTerms] = usePersistedState('acceptTerms', false)
  const [aguarde, setAguarde] = useState(false)
  const [reSend, setReSend] = useState(true)
  const [timer, setTimer] = useState(0)

  const history = useHistory()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [txtModal, setTxtModal] = useState('')
  const [flagRota, setFlagRota] = useState(false)
  const [rota, setRota] = useState('')
  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
    if (flagRota) {
      history.push(`${rota}`)
      setFlagRota(false)
    }
  }, [flagRota, history, rota])

  const handleConfirmaAdesao = useCallback(async () => {
    setAguarde(true)

    const parametros = Base64.encode(
      `{
        "token": "${configData.token}",
        "versao": "${configData.tipo}",
        "plano": "${configData.plano}",
        "nome": "${userData.name.toLowerCase()}",
        "cpf": "${userData.cpf.replaceAll('.', '').replace('-', '')}",
        "email": "${userData.email}",
        "telefone": "55${userData.phone
          .replace('(', '')
          .replace(') ', '')
          .replace('-', '')}",
        "patrocinadora":"${userData.patrocinadora}",
        "inscPlano":"${
          userData.inscPlano === undefined
            ? ''
            : userData.inscPlano.toUpperCase()
        }",
        "salario": "${userDetails.salario
          .toFixed(2)
          .toString()
          .replace('.', ',')}",
        "valor": "${userDetails.contribuicaoBasica
          .toString()
          .replace('.', ',')}",
        "pctCtbBasica": "${
          userDetails.pctContribuicaoBasica === undefined
            ? '0'
            : userDetails.pctContribuicaoBasica.toString().replace('.', ',')
        }",
        "valorPatroc": "${
          userDetails.contribuicaoPatrocinadora === undefined
            ? ''
            : userDetails.contribuicaoPatrocinadora.toString().replace('.', ',')
        }",
        "pctCtbPatroc": "${
          userDetails.pctContribuicaoBasica === undefined
            ? ''
            : userDetails.pctContribuicaoBasica.toString().replace('.', ',')
        }",
        "valorsuplementar": "${
          userDetails.contribuicaoSuplementar === undefined
            ? ''
            : userDetails.contribuicaoSuplementar.toString().replace('.', ',')
        }",
        "pctCtbSuplementar": "${
          userDetails.pctContribuicaoSuplementar === undefined
            ? ''
            : userDetails.pctContribuicaoSuplementar
                .toString()
                .replace('.', ',')
        }",
        "periodicidade": "${
          userDetails.periodicidade === undefined
            ? ''
            : userDetails.periodicidade
        }",
        "politicamenteexposto": "${userDetails.ppe}",
        "usperson": "${userDetails.usperson}",
        "beneficiarios": [${participants.map(
          participant => `
          {"nome": "${participant.data.name}",
          "cpf": "${participant.data.cpf.replaceAll('.', '').replace('-', '')}",
          "nascimento": "${participant.data.birthdate}",
          "tipo": "${participant.details.tipoBen}",
          "fator": "${participant.details.proporcao
            .toFixed(2)
            .toString()
            .replace('.', ',')}",
          "parentesco": "${
            participant.details.grauParentesco === '' ||
            participant.details.grauParentesco === undefined
              ? 0
              : participant.details.grauParentesco
          }"}`,
        )}]
        }`,
    )

    const parametrosFinal = Base64.encode(parametros)

    await api
      .post(`wsAdesao.rule?sys=ADZ&Entrada=${parametrosFinal}`)
      .then(() => {
        localStorage.removeItem('@BRFPrev:acceptTerms')
        localStorage.removeItem('@BRFPrev:erroProps')
        localStorage.removeItem('@BRFPrev:participantsGroup')
        localStorage.removeItem('@BRFPrev:userData')
        localStorage.removeItem('@BRFPrev:userDetails')

        apiRDStation(
          '/conclusion',
          userData.name,
          userData.email,
          userData.phone,
        )

        history.push('/end')
      })
      .catch(res => {
        if (res.message === 'Request failed with status code 401') {
          setFlagRota(true)
          setRota('/')
          setTxtModal('Tempo de sessão expirado')
          setIsModalOpen(true)
          // history.push('/')
        } else if (res.message === 'Request failed with status code 406') {
          setTxtModal(
            'Adesão não autorizada. Verifique se você já possui cadastro no plano.',
          )
          setIsModalOpen(true)
          setReSend(false)
          setTimer(20)
          setAguarde(false)
        } else if (res.message === 'Network Error') {
          setErroProps({
            title: res.message,
            description: 'Falha na conexão como servidor',
          })
          history.push('/erro')
        } else if (res.message === 'Request failed with status code 500') {
          setErroProps({
            title: 'Erro interno no servidor',
            description: res.message,
          })
          history.push('/erro')
        } else if (res.message === 'Request failed with status code 408') {
          setFlagRota(true)
          setRota('/')
          setTxtModal('Tempo de sessão expirado')
          setIsModalOpen(true)
          // history.push('/')
        } else {
          setReSend(false)
          setTimer(20)
          setAguarde(false)
          setTxtModal('Ops, algo deu errado. Tente novamente mais tarde.')
          setIsModalOpen(true)
        }
      })
  }, [configData, history, participants, setErroProps, userData, userDetails])

  const toggleAcceptTerms = useCallback(() => {
    setAcceptTerms(!acceptTerms)
  }, [acceptTerms, setAcceptTerms])

  useEffect(() => {
    const countTimer = (): void => {
      if (timer > 1) {
        setTimer(timer - 1)
      } else if (timer === 1) {
        setTimer(timer - 1)
        setReSend(true)
      }
    }
    const interval = setInterval(countTimer, 1000)
    return () => clearInterval(interval)
  }, [timer])

  const dependents = participants.filter(
    participant => participant.details.grauParentesco !== undefined,
  )

  return (
    <>
      <Header />
      <Container>
        <Content>
          <strong>Resumo</strong>

          <Participants>
            {/* <div className="titular">
              <div className="infos">
                <p>{userData.name}</p>
                <small>{calculaIdade(userData.birthdate)} anos</small>
              </div>
            </div> */}

            <InfoContent>
              <div>
                <div>
                  <p>Valor total de contribuição básica ao Plano: </p>
                  <small>(participante + empresa)</small>
                </div>
                <strong>
                  {formatValue(userDetails.contribuicaoBasica * 2)}
                </strong>
              </div>
              <div>
                <p>Valor da contribuição adicional: </p>
                <strong>
                  {formatValue(userDetails.contribuicaoSuplementar)}
                </strong>
              </div>
            </InfoContent>

            {participants.length > 0 ? (
              <>
                <Line />
                <h3>Beneficiários:</h3>
                {dependents.map(d => (
                  <div key={Math.random()} className="dependents">
                    <div className="infos">
                      <p>{d.data.name}</p>
                      <small>{d.details.dcrGrauParentesco}</small>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              // <h3>Nenhum bebeficiário informado.</h3>
              <></>
            )}
          </Participants>

          <Confirmation>
            <strong>Confirmação de Adesão</strong>
            <p>
              Eu <b>{userData.name}</b>, inscrito no CPF <b>{userData.cpf}</b>,
              declaro que ao clicar em <b>Confirmar Adesão</b>, confirmo minha
              adesão ao <b>Plano de Benefícios III</b> administrado pela BRF
              Previdência e que as informações prestadas são verídicas.
            </p>

            <div>
              <small>
                Aceito os{' '}
                <a
                  href="https://www.brfprevidencia.com.br/politica-de-privacidade/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Termos de Uso
                </a>
              </small>
              <Switch
                onChange={toggleAcceptTerms}
                checked={acceptTerms}
                checkedIcon={false}
                uncheckedIcon={false}
                height={20}
                width={40}
                handleDiameter={16}
                offColor="#FBADB4"
                offHandleColor="#fff"
                onHandleColor="#fff"
                onColor="#31D19E"
              />
            </div>
          </Confirmation>
        </Content>

        <Button
          type="button"
          color="orange"
          width="large"
          disabled={!acceptTerms || !reSend || aguarde}
          onClick={handleConfirmaAdesao}
        >
          {!aguarde ? (
            !reSend ? (
              <>
                <FiClock size={45} />
                <span>
                  Tente novamente em {timer < 10 ? `0${timer}` : timer} segundos
                </span>
              </>
            ) : (
              <>
                <FiCheck size={45} />
                <span>Confirmar adesão</span>
              </>
            )
          ) : (
            <>
              <CircularProgress color="inherit" />
              <span>Aguarde</span>
            </>
          )}
        </Button>
        <BtnVoltar type="button" onClick={() => history.goBack()}>
          &lt; Anterior
        </BtnVoltar>
        <ModalBox isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          {txtModal}
          <BtnModal isActive onClick={() => handleCloseModal()}>
            Ok
          </BtnModal>
        </ModalBox>
      </Container>
    </>
  )
}

export default Conclusion
