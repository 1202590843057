export default function clientConfig() {
  const config = {
    codCliente: 2144, // HMG 9144 // PRD 2144
    plano: 3,
    tipo: 2, // Patrocinado
  }

  return config
}


