/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useCallback, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'

import { FiCheck } from 'react-icons/fi'

import usePersistedState from '../../hooks/usePersistedState'

import Button from '../../components/Button'
import Header from '../../components/Header'

import {
  Container,
  Content,
  RadioButton,
  CheckBox,
  BtnVoltar,
  BtnContato,
} from './styles'
import { UserDetails, UserData } from '../../utils/interfaces'
import apiRDStation from '../../services/apiRDStation'

const FatcaInfo: React.FC = () => {
  const [userDetails, setUserDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)

  const [ppe, setPPE] = useState('N')
  const [checkFatca1, setCheckFatca1] = useState(false)
  const [checkFatca2, setCheckFatca2] = useState(false)
  const [checkFatca3, setCheckFatca3] = useState(false)
  const [checkFatca4, setCheckFatca4] = useState(false)
  const [checkFatca5, setCheckFatca5] = useState(false)
  const [checkFatca6, setCheckFatca6] = useState(false)
  const [checkFatcaR, setCheckFatcaR] = useState(false)
  const [checkFatcaN, setCheckFatcaN] = useState(false)

  const formRef = useRef<FormHandles>(null)
  const history = useHistory()

  const handleSubmit = useCallback(async () => {
    const usperson =
      (checkFatcaR || checkFatcaN) &&
      (!checkFatca1 ||
        !checkFatca2 ||
        !checkFatca3 ||
        !checkFatca4 ||
        !checkFatca5 ||
        !checkFatca6)
        ? 'N'
        : 'S'

    await setUserDetails({
      ...userDetails,
      ppe,
      usperson,
    })
    apiRDStation('/ppe-fatca', userData.name, userData.email, userData.phone)

    // history.push('/conclusion')
    history.push('/lgpd')
  }, [
    checkFatca1,
    checkFatca2,
    checkFatca3,
    checkFatca4,
    checkFatca5,
    checkFatca6,
    checkFatcaN,
    checkFatcaR,
    history,
    ppe,
    setUserDetails,
    userDetails,
    userData,
  ])

  return (
    <>
      <Header />
      <Container>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Content>
            <strong>Pessoa politicamente exposta (PPE)?</strong>
            <small>
              De acordo com a instrução MPS/SPC nº 26 de 01/09/2008, Pessoa
              Politicamente Exposta é aquela que exerce ou exerceu nos últimos 5
              anos, no Brasil ou no exterior, cargo, emprego ou função pública
              relevante. Também é considerada aquela que tenha representantes,
              familiares e outras pessoas de seu relacionamento próximo que
              exercem ou tenham exercido nos últimos 5 anos, no Brasil ou no
              exterior, cargo, emprego ou função pública relevante.
            </small>

            <RadioButton>
              <div>
                <BtnContato
                  type="button"
                  isActive={ppe === 'S'}
                  onClick={() => setPPE('S')}
                >
                  Sim
                </BtnContato>
                <BtnContato
                  type="button"
                  isActive={ppe === 'N'}
                  onClick={() => setPPE('N')}
                >
                  Não
                </BtnContato>
              </div>
            </RadioButton>
          </Content>

          <Content>
            <strong>E-Financeira / FATCA</strong>
            <small>
              O preenchimento destas informações visa atender à Lei norte
              americana FATCA - Foreing Account Tax Compliance Act (Ato de
              conformidade Fiscal de Contas Estrangeiras), previsto no decreto
              brasileiro nº 8.506 de 24 de agosto de 2015, que tem como objetivo
              identificar contribuintes do imposto norte americano a fim de
              combater a evasão fiscal dos Estados Unidos.
            </small>

            <CheckBox>
              <div>
                <input
                  type="checkbox"
                  name="Check1"
                  placeholder="Check1"
                  disabled={checkFatcaR || checkFatcaN}
                  checked={checkFatcaR === false && checkFatca1 === true}
                  onChange={e => setCheckFatca1(e.target.checked)}
                />
                <label>Sou cidadão ou residente dos EUA.</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  name="Check2"
                  placeholder="Check2"
                  disabled={checkFatcaR || checkFatcaN}
                  checked={checkFatcaR === false && checkFatca2 === true}
                  onChange={e => setCheckFatca2(e.target.checked)}
                />
                <label>
                  Possuo visto de permanência nos EUA, do tipo Green Card.
                </label>
              </div>
              <div>
                <input
                  type="checkbox"
                  name="Check3"
                  placeholder="Check3"
                  disabled={checkFatcaR || checkFatcaN}
                  checked={checkFatcaR === false && checkFatca3 === true}
                  onChange={e => setCheckFatca3(e.target.checked)}
                />
                <label>
                  Possuo endereço de residência ou telefone atual nos EUA.
                </label>
              </div>
              <div>
                <input
                  type="checkbox"
                  name="Check4"
                  placeholder="Check4"
                  disabled={checkFatcaR || checkFatcaN}
                  checked={checkFatcaR === false && checkFatca4 === true}
                  onChange={e => setCheckFatca4(e.target.checked)}
                />
                <label>
                  Possuo conta bancária em instituições financeiras nos EUA.
                </label>
              </div>
              <div>
                <input
                  type="checkbox"
                  name="Check5"
                  placeholder="Check5"
                  disabled={checkFatcaR || checkFatcaN}
                  checked={checkFatcaR === false && checkFatca5 === true}
                  onChange={e => setCheckFatca5(e.target.checked)}
                />
                <label>
                  Concedi procuração válida ou outras atribuições a uma pessoa
                  que tenha um endereço nos EUA.
                </label>
              </div>
              <div>
                <input
                  type="checkbox"
                  name="Check6"
                  placeholder="Check6"
                  disabled={checkFatcaR || checkFatcaN}
                  checked={checkFatcaR === false && checkFatca6 === true}
                  onChange={e => setCheckFatca6(e.target.checked)}
                />
                <label>
                  Possuo um endereço para correspondência com a característica
                  de &quot;aos cuidados de&quot; nos EUA como único endereço
                  disponível.
                </label>
              </div>
              <div>
                <input
                  type="checkbox"
                  name="Check7"
                  placeholder="Check7"
                  disabled={checkFatcaN}
                  checked={checkFatcaN === false && checkFatcaR === true}
                  onChange={e => setCheckFatcaR(e.target.checked)}
                />
                <label>
                  Renunciei/abdiquei da cidadania, Green Card ou nacionalidade
                  dos EUA.
                </label>
              </div>

              <div className="checkboxNo">
                <input
                  type="checkbox"
                  name="CheckN"
                  checked={checkFatcaN}
                  onChange={e => setCheckFatcaN(e.target.checked)}
                />
                <label onClick={() => setCheckFatcaN(!checkFatcaN)}>
                  NÃO, nenhuma das alternativas anteriores.
                </label>
              </div>
            </CheckBox>
          </Content>
        </Form>

        <Button
          type="button"
          fontSize="normal"
          color="orange"
          width="medium"
          onClick={handleSubmit}
          disabled={
            !checkFatca1 &&
            !checkFatca2 &&
            !checkFatca3 &&
            !checkFatca4 &&
            !checkFatca5 &&
            !checkFatca6 &&
            !checkFatcaR &&
            !checkFatcaN
          }
        >
          Continuar
          {/* <FiCheck size={45} />
          <span>
          Pronto! Concluir
          </span> */}
        </Button>
        <BtnVoltar type="button" onClick={() => history.goBack()}>
          &lt; Anterior
        </BtnVoltar>
      </Container>
    </>
  )
}

export default FatcaInfo
