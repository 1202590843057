import { transparentize } from 'polished'
import styled from 'styled-components'

interface BtnContatoProps {
  isActive: boolean
}

interface BtnProps {
  isSelected?: boolean
}

export const Container = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;

  margin: 0 auto;
  margin-bottom: 15px;
  max-width: 600px;
  padding: 0 5px;

  @media screen and (max-width: 766px) {
    max-width: 550px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  background: #FFF;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 6px 12px rgba(61, 69, 67, 0.05);

  width: 100%;
  padding: 30px 25px;
  margin-bottom: 10px;
  text-align: center;

  strong {
    text-align: center;
    font-size: 18px;
    color: #FF8636;

    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }

  > p {
    margin-bottom: 10px;
  }

  > div.rules {
    margin: 12px auto 0;
    text-align: center;
    width: 75%;
    font-style: italic;
  }

  .collapse {
    width: 100%;
  }

  @media screen and (max-width: 766px) {
    padding: 20px 15px;

    > div.rules {
      width: 90%;
    }
  }
`

export const PercentualBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > div {
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;

    > div {
      margin-top: 5px;
    }
  }
`

export const ButtonSimulationCalc = styled.button<BtnProps>`
  width: 35px;
  height: 35px;
  margin: 0 5px;
  border-radius: 4px;
  border: 1px solid ${props => props.isSelected ? '#FDA10D' : '#c1c1c1'};
  background-color: ${props => props.isSelected ? 'rgba(255, 196, 86,0.2)' : '#F4F4F9'};
`

export const ValueSimulation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #c1c1c1;

`

export const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;

  > div {
    display: flex;
    justify-content: space-between;

    width: 100%;
    margin-top: 3px;

    strong {
      font-size: 16px;
      color: ${props => props.theme.colors.mainColor};
    }

    small {
      font-size: 12px;
      display: flex;
    }
  }

  span {
    font-size: 11px;
    color: ${props => props.theme.colors.placeholder};
    font-style: italic;
    margin-top: 15px;
  }

  @media screen and (max-width: 600px) {
    p {
      text-align: left;
    }

    > div {
      margin-top: 8px;

      small {
        text-align: left;
      }
    }
  }
`

export const RadioButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position:relative;

  width: 100%;
  padding: 16px 12px;
  margin-bottom: 12px;

  background: initial;
  border-radius: 4px;
  border: 1px solid ${transparentize(0.4, '#AEAEAE')};
  color: ${props => props.theme.colors.placeholder};
  transition: .2s;

  > label {
    transform: translateY(-12px);
    font-size: 12px;
    font-weight: bold;
  }

  > div {
    width: 100%;
    margin-top: 3px;
    margin-bottom: 3px;
    display: flex;
    flex-direction:row;
    margin: 0 auto;
    align-items: center;
    justify-content: space-evenly;

    &::placeholder {
      color: ${ props => props.theme.colors.placeholder};
    }

  }
`

export const NoContribCheck = styled.div`
  >div {
    margin-top: 10px;
    margin-bottom: 5px;
    justify-content: center;
    align-content: center;
    flex-direction: row;

    input[type=checkbox] {
      height: 14px;
      width: 14px;
      margin-right: 5px;
    }
    > label {
      color: black;
      margin-left: 5px;
      font-size: 16px;
      text-align: center;
    }
  }
`

export const BtnContato = styled.button<BtnContatoProps>`
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  padding: 3px;
  background: ${props => props.isActive ? 'linear-gradient(15deg, #F89A19, #FFD14D)' : 'transparent'} ;
  border: ${props => props.isActive ? 'none' : 'solid 1px #F89A19'};
  color: ${props => props.isActive? '#fff' : props.theme.colors.placeholder} ;
  border-radius: 4px;

  &:focus {
    text-decoration: underline;
  }
`

export const BtnVoltar = styled.button`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  border: none;
  background-color: unset;
  border-bottom: 1px solid transparent;
  color: ${({ theme }) => theme.colors.text};

  margin-bottom: 15px;

  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }

  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  }
`

export const Line = styled.div`
  width: 80%;
  height: 1px;
  margin: 15px auto 20px;
  background-color: ${props => props.theme.colors.backgroundTertiary};
`

interface BtnModalProps {
  isActive: boolean;
}
export const BtnModal = styled.button<BtnModalProps>`
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  padding: 3px;
  background: ${props => props.isActive ? 'linear-gradient(15deg, #F89A19, #FFD14D)' : 'transparent'} ;
  border: ${props => props.isActive ? 'none' : 'solid 1px #F89A19'};
  color: ${props => props.isActive? '#fff' : props.theme.colors.placeholder} ;
  border-radius: 4px;
  margin: 20px 20px 0 0;
  &:focus {
    text-decoration: underline;
  }
`
